<template>
    <div class="whole-container">
        <b-alert v-model="showErrorDismissibleAlert" variant="danger" dismissible>
            {{errorMsg}}
        </b-alert> 
        <b-alert v-model="showSuccessDismissibleAlert" variant="success" dismissible>
            傾斜計算がクリップボードにコピーされました。
        </b-alert>
        <div class="row input-container">
            <div class="input-wrapper col-8">
                <b-input-group prepend="合計金額" append="円">
                    <b-form-input type="number" v-model="totalAmount"></b-form-input>
                </b-input-group>  
            </div>
            <div class="col-4">
                <b-dropdown id="dropdown-1" :text="roundTitle">
                    <b-dropdown-item @click="setRoundDigit(1)">1円単位</b-dropdown-item>
                    <b-dropdown-item @click="setRoundDigit(10)">10円単位</b-dropdown-item>
                    <b-dropdown-item @click="setRoundDigit(100)">100円単位</b-dropdown-item>
                    <b-dropdown-item @click="setRoundDigit(1000)">1000円単位</b-dropdown-item>
                </b-dropdown>
            </div>    
        </div>
        <div class="exe-container">
            <div class="exe-wrapper">
                <div class="row">
                    <b-button variant="secondary" class="add-button" @click="addGroup">グループを追加する</b-button>
                </div>
                <div class="row">
                    <b-button variant="secondary" class="add-button" @click="copy2Clipboard">コピー</b-button>
                </div>
                <div class="row">
                    <b-button variant="success" class="add-button" @click="calPayment">計算する</b-button>
                </div>                            
            </div> 
        </div>

        <div class="group-container">
            <div v-for="group in paymentGroups" :key="group.id">
                <PaymentClass :group="group" @delete="deleteGroup"></PaymentClass>
            </div>
        </div>
    </div>
</template>

<script>
import PaymentClass from '../components/PaymentClass.vue'

export default {
    data: function() {
        return {
            classNum: 0,
            totalAmount: 0,
            paymentGroups: [],
            showSuccessDismissibleAlert: false,
            roundDigit: 100,
            roundTitle: '100円単位',
            errorMsg: '',
            showErrorDismissibleAlert: false
        }
    },
    components: {
        PaymentClass
    },
    methods: {
        setRoundDigit(round) {
            this.roundDigit  = round;
            this.roundTitle = String(round) + "円単位";
        },
        addGroup() {
            const newGroup = {}
            newGroup.id = this.classNum+1
            newGroup.name = "グループ "+String(newGroup.id)
            newGroup.rank = 1
            newGroup.amount = 0
            newGroup.peopleNum = 1
            this.paymentGroups.push(newGroup)
            this.classNum += 1
        },
        calPayment() {
            if(this.roundDigit == null){
                this.showErrorDismissibleAlert = true;
                this.errorMsg = "単位が設定されていません";
                return;
            }
            const burdens = []
            const peopleNums = []
            this.paymentGroups.forEach(paymentGroup => {
                const burden = paymentGroup.rank * paymentGroup.peopleNum
                burdens.push(Number(burden))
                peopleNums.push(Number(paymentGroup.peopleNum))
            })
            
            const burdenTotal = burdens.reduce((sum, element) => sum + element, 0)

            const paymentAmounts = []
            for(let i=1;i<=burdens.length;i++){
                const paymentAmountsForEachGroup = Number(this.totalAmount) * burdens[i-1] / burdenTotal
                const paymentAmountsForEachPerson = paymentAmountsForEachGroup / peopleNums[i-1]
                const a = this.roundAmount(paymentAmountsForEachPerson, this.roundDigit)
                paymentAmounts.push(a)
            }

            // debug
            // console.log("burdens: ", burdens)
            // console.log("peopleNums: ", peopleNums)
            // console.log("paymentAmounts: ", paymentAmounts)

            for(let i=1;i<=this.paymentGroups.length;i++) {
                this.paymentGroups[i-1].amount = paymentAmounts[i-1]
            }
        },
        roundAmount(rawAmount, roundDigit) {
            return Math.ceil(rawAmount / roundDigit) * roundDigit;
        },
        deleteGroup(...args) {
            const targetId = Number(args[0])
            this.classNum -= 1
            this.paymentGroups.filter(group => {
                if(group.id == targetId) {
                    const targetIndex = this.paymentGroups.indexOf(group)
                    this.paymentGroups.splice(targetIndex,1)
                }
            })
        },
        copy2Clipboard() {
            let text = ""
            for(let i=0;i<this.paymentGroups.length;i++) {
                text = text + "《"+this.paymentGroups[i].name + "》\n"
                        + "負担比率："+this.paymentGroups[i].rank + "\n"
                        + "負担金額："+this.paymentGroups[i].amount + "円\n\n"
            }
            navigator.clipboard.writeText(text)
            .then(() => {
                this.showSuccessDismissibleAlert = true
            })
            .catch(e => {
                console.error("error: ", e)
            })
        }
    }
}
</script>

<style>
.whole-container {
    min-width: 300px;
    max-width: 600px;
    margin: 0 auto;
}
.input-wrapper {
    width: 80%;
    /* margin: 0 auto; */
}
.add-button {
    margin: 1% auto;
    width: 80%;
}
.input-container {
    margin: 5px;
    padding: 5px;
}
.exe-wrapper{
    width:80%;
    margin: 0 auto;
}
.group-container{
    width: 80%;
    margin: 0 auto;
}
</style>