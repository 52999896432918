<template>
    <div>
        <b-card class="class-card">
            <b-input-group prepend="グループ名" class="mt-3">
                <b-form-input v-model="group.name" :value="group.name"></b-form-input>
            </b-input-group>     
            
            <b-input-group prepend="人数" class="mt-3">
                <b-form-input v-model="group.peopleNum" :value="group.peopleNum"></b-form-input>
                <b-input-group-append>
                <b-button variant="outline-info" @click="minusPeopleNum">ー</b-button>
                <b-button variant="info" @click="plusPeopleNum">＋</b-button>
                </b-input-group-append>
            </b-input-group>     
            <b-input-group prepend="比率" class="mt-3">
                <b-form-input v-model="group.rank" :value="group.rank"></b-form-input>
                <b-input-group-append>
                <b-button variant="outline-info" @click="minusRank">ー</b-button>
                <b-button variant="info" @click="plusRank">＋</b-button>
                </b-input-group-append>
            </b-input-group>
            <div class="row payment-container">
                <div class="col-5 cash-coin">
                    <img src="@/assets/cash-coin.svg" alt="">
                </div>
                <div class="col-7 amount-container">
                    <h5>支払金額：{{group.amount}} 円</h5>
                </div>
            </div>     
            <b-button class="delete-button" variant="outline-danger" @click="deleteGroup">削除</b-button>
        </b-card>
    </div>
</template>

<script>
export default {
    props: ['group'],
    data: function() {
        return {
            
        }
    },
    methods: {
        deleteGroup() {
            this.$emit('delete', this.group.id);
            this.$destroy();
            this.$el.parentNode.removeChild(this.$el);
            //paymentGroupsから自身を削除、この際にはidを利用する。classNumを1減らす。
        },
        minusPeopleNum() {
            if(this.group.peopleNum==0) {
                return;
            }
            this.group.peopleNum = Number(this.group.peopleNum) - 1
        },
        plusPeopleNum() {
            this.group.peopleNum = Number(this.group.peopleNum) + 1
        },
        minusRank() {
            if(this.group.rank==0) {
                return;
            }
            this.group.rank = Number(this.group.rank) - 1
        },
        plusRank() {
            this.group.rank = Number(this.group.rank) + 1
        }
    }
}
</script>

<style>
p {
    margin-bottom: 0;
}
.class-card {
    margin-top: 15px;
    margin-right: 0;
    margin-left: 0;
}
.settings-wrapper {
    padding-right: 0;
    padding-left: 0;
}
.delete-button {
    margin-top: 1%;
    width: 80%;
}
.payment-container {
    margin: 15px;
}
.cash-coin {
    display: flex;
    justify-content: flex-end;
}
.amount-container {
    display: flex;
    justify-content: flex-start;
}
</style>