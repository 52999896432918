import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Aboutus from './views/Aboutus.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
        path: '/aboutus',
        name: 'aboutus',
        component: Aboutus
    }
  ]
})
